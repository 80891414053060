<template>
	<span>
		<span v-if="value == 'nuevo'"><i class="ui red mail icon" :style="{ fontSize: size || 1.2 + 'em' }"></i><span v-if="label"> Nuevo</span></span>
		<span v-if="value == 'en_proceso'"><i class="ui orange settings icon" :style="{ fontSize: size || 1.2 + 'em' }"></i><span v-if="label"> En Proceso</span></span>
		<span v-if="value == 'encargado'"><i class="ui blue calendar icon" :style="{ fontSize: size || 1.2 + 'em' }"></i><span v-if="label"> Encargado</span></span>
		<span v-if="value == 'facturado'"><i class="ui green check square icon" :style="{ fontSize: size || 1.2 + 'em' }"></i><span v-if="label"> Facturado</span></span>
		<span v-if="value == 'anulado'"><i class="ui grey ban icon" :style="{ fontSize: size || 1.2 + 'em' }"></i><span v-if="label"> Anulado</span></span>
	</span>
</template>

<script>
export default {
	name: 'OrdersStatusWidget',
	props: ['value', 'label', 'size'],
}
</script>

<style scoped>

</style>