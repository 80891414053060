<template>
	<div class="orders-admin">
		<div class="filters ui small vertical inverted menu">
			<div class="item">
				<div class="header">Estado</div>
			</div>
			<div @click.stop="getOrders" :class="{'active': filterValue == null}" class="link item"><i class="ui inverted red mail icon"></i>Nuevos</div>
			<div @click.stop="getOrders('en_proceso')" :class="{ 'active': filterValue == 'en_proceso' }" class="link item"><i class="ui inverted orange settings icon"></i>En proceso</div>
			<div @click.stop="getOrders('encargado')" :class="{ 'active': filterValue == 'encargado' }" class="link item"><i class="ui inverted blue calendar icon"></i>Encargados</div>
			<div @click.stop="getOrders('facturado')" :class="{ 'active': filterValue == 'facturado' }" class="link item"><i class="ui inverted green check square icon"></i>Facturados</div>
			<div @click.stop="getOrders('anulado')" :class="{ 'active': filterValue == 'anulado' }" class="link item"><i class="ui inverted grey ban icon"></i>Anulados</div>
			<div class="item">
				<div class="header">Fecha</div>
			</div>
			<div @click.stop="getOrders(1)" :class="{ 'active': filterValue == 1 }" class="link item">Hoy</div>
			<div @click.stop="getOrders(2)" :class="{ 'active': filterValue == 2 }" class="link item">2 últimos días</div>
			<div @click.stop="getOrders(7)" :class="{ 'active': filterValue == 7 }" class="link item">7 últimos días</div>
			<div @click.stop="getOrders('todos')" :class="{ 'active': filterValue == 'todos' }" class="link item">Todos</div>
		</div>
		<div class="list-wraper">
			<div class="list">
				<div class="header">Admin Pedidos</div>
				<div class="order" v-for="pedido in ordersByDateDesc" :key="pedido.numero" :pedido="pedido" @click.stop.prevent="viewDetail(pedido)">
					<a class="ui grey ribbon label">{{ pedido.pedido_en | timestampPedidos }}</a>
					<OrdersStatusWidget :value="lastStatus(pedido)" :size="1.6" :label="true"></OrdersStatusWidget>
					<ul>
						<li><label>EUR </label>{{ pedido.libro.precio | formatEU }}</li>
						<li><label>pedido </label>{{ pedido.numero }}</li>
					</ul>
					<div>
						<span class="product-type" :class="{ ebook: pedido.libro.ebook }">{{ pedido.libro.ebook ? 'e-book' : 'libro' }}</span>
						<span>{{ pedido.libro.titulo }}</span>
					</div>
					<div>{{ pedido.pedido_por.nombre }}, {{ pedido.pedido_por.apellidos }} | {{ pedido.biblioteca }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, query, orderByChild, onChildAdded, off, equalTo, startAt } from "firebase/database"
import OrdersStatusWidget from '../components/OrdersStatusWidget.vue'

export default {
	name: 'AdminListView',
	components: {
		OrdersStatusWidget
	},
	data: function () {
		return {
			pedidos: [],
			user: {},
			asignado: null,
			nroPedidos: null,
			consumido: null,
			dataRef: null,
			filterKey: null,
			filterValue: null,
		}
	},
	created: function () {
		this.getOrders()
	},
	computed: {
		ordersByDateDesc: function () {
			const ordersCopy = [...this.pedidos]
			return ordersCopy.sort((a, b) => (a.pedido_en > b.pedido_en) ? -1 : 1)
		}
	},
	methods: {
		getUser: function (next) {
			var that = this
			if (this.$root.reportSelectedUser) {
				// viene de haber pulsado un usuario en el informe
				ref.child('usuarios')
					.child(this.$root.reportSelectedUser)
					.on(
						'value', //zzz
						function (snap) {
							that.user = snap.val()
							that.user.uid = snap.key
							next()
						}
					)
			} else {
				// los pedidos son los del propio usuario logeado
				this.user = this.$root.yo
				next()
			}
		},
		getStats: function () {
			var dataLib = this.user.bibliotecas[this.$root.currentSubject.id]
			this.asignado = dataLib.asignado
			this.nroPedidos = dataLib.pedidos
			this.consumido = dataLib.consumido
		},
		getOrders: function (filter) {
			this.filterValue = filter // Activa el item del menu clickado

			if (this.dataRef) {
				off(this.dataRef, 'child_added', this.pushOrderToList) // Detaches the listener
			}

			this.setdataRef(filter) // Decide la referencia a la BD

			this.pedidos = [] // limpia la lista de pedidos

			onChildAdded(this.dataRef, this.pushOrderToList)  // Attaches a listener

		},
		setdataRef: function (filter) {
			// Decide la referencia a la BD
			if (['en_proceso', 'encargado', 'facturado', 'anulado'].indexOf(filter) > -1) {
				// this.dataRef = ref.child('pedidos').orderByChild('estado_actual').equalTo(filter)
				this.dataRef = query(ref(db, 'pedidos'), orderByChild('estado_actual'), equalTo(filter))

			} else if ([1, 2, 7].indexOf(filter) > -1) {
				// var desde = moment()
				// 	.subtract(filter - 1, 'days')
				// 	.startOf('day')
				// 	.valueOf()
				const d = new Date(); // ahora
				d.setUTCHours(0, 0, 0, 0) // elimina horas, minutos, segundos y milisegundos
				d.setDate(d.getDate() - (filter - 1)) // resta los días indicados
				// this.dataRef = ref.child('pedidos').orderByChild('pedido_en').startAt(d.valueOf())
				this.dataRef = query(ref(db, 'pedidos'), orderByChild('pedido_en'), startAt(d.valueOf()))

			} else if ('todos' == filter) {
				// this.dataRef = ref.child('pedidos')
				this.dataRef = ref(db, 'pedidos')

			} else {
				// por defecto
				this.filterValue = null
				// this.dataRef = ref.child('pedidos').orderByChild('estado_actual').equalTo(null)
				this.dataRef = query(ref(db, 'pedidos'), orderByChild('estado_actual'), equalTo(null))
			}
		},
		pushOrderToList: function (snapshot) {
			var pedido = snapshot.val()
			pedido.numero = snapshot.key
			this.pedidos.push(pedido)
		},
		viewDetail: function (order) {
			this.$root.orderDetail = order
			this.$root.route('AdminOrderView')
		},
		lastStatus: function (pedido) {
			var history = pedido.historia
			if (history) {
				var keys = Object.keys(history)
				var key = keys[keys.length - 1]
				var status = history[key].estado
				return status
			} else return 'nuevo'
		},
	},
}
</script>

<style scoped>
.orders-admin {
	flex: 1 1 auto;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.filters.ui.menu {
	margin: 0 !important;
	/* font-family: 'Fira Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif; */
	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol, 'Noto Color Emoji';
	font-size: 15px;
}

.filters.ui.menu .link.item {
	color: #999;
}

.filters.ui.menu .item>.header {
	font-size: 1.3em;
	font-style: italic;
	font-weight: 300;
	color: Chocolate;
	margin: 0.5em 0 0;
}

.list-wraper {
	width: 100%;
	overflow-y: auto;
}

.list-wraper::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

.list-wraper::-webkit-scrollbar-thumb {
	background: #434343;
	border-radius: 16px;
	box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
}

.list-wraper::-webkit-scrollbar-track {
	background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}

.list {
	width: 100%;
	max-width: 60em;
	margin: 1em auto;
}

.list>.header {
	text-align: center;
	margin: 1em 0 1.5em;
	font-size: 1.3em;
	font-style: italic;
	color: Chocolate;
}


/* Order */
.order {
	cursor: pointer;
	background-color: hsl(0, 0%, 92%);
	margin: 1em 0.5em 0.5em 1.5em;
	padding: 0.6em 1em 0.6em;
	border: 1px solid hsl(0, 0%, 73%);
	border-radius: 5px;
}

.order.anulado {
	background-color: hsla(0, 0%, 82%, 1);
}

.order .product-type {
	font-weight: 800;
	color: hsl(177, 100%, 30%);
	margin-right: 0.7rem;
}

.order .product-type.ebook {
	color: chocolate;
}

.order>ul {
	/*Importe y nro. pedido*/
	float: right;
	font-weight: 500;
	margin: 0;
	padding: 0;
}

.order>ul>li {
	color: hsl(177, 100%, 30%);
	display: inline;
	list-style: none;
	padding-left: 1em;
}

.order>ul>li>label {
	color: #888;
	font-size: 0.8em;
}

.order>ul>li:last-child {
	color: DarkOrange;
}

.order>div:nth-of-type(1) {
	/*Titulo*/
	padding-top: 0.3em;
	font-weight: 500;
	color: hsl(0, 0%, 10%);
}

.order>div:nth-of-type(2) {
	/*Referencia*/
	font-style: italic;
	color: hsl(0, 0%, 10%);
}

.order .ui.grey.ribbon.label:after {
	border-right-color: hsl(0, 0%, 33%);
}
</style>