<template>
	<div class="login view">
		<form class="ui form error">
			<div v-if="loginError" class="ui icon negative message">
				<i class="warning circle icon"></i>
				<div class="content"> {{ loginError }} </div>
			</div>
			<div class="ui left icon fluid input">
				<i class="mail icon"></i>
				<input v-model="email" type="text" placeholder="Correo Electrónico">
			</div>
			<div class="ui left icon fluid input">
				<i class="asterisk icon"></i>
				<input v-model="password" type="password" placeholder="Contraseña">
			</div>
			<button @click.stop.prevent="login" class="ui large green button right floated">Entrar</button>
		</form>
	</div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database"
import { db } from '../firebaseInit.js'

export default {
	name: 'LoginView',
	data: function () {
		return {
			email: '',
			password: '',
			loginError: null,
		}
	},
	methods: {
		login: function () {
			const auth = getAuth();
			signInWithEmailAndPassword(auth, this.email, this.password)
				// .then((userCredential) => {
				// 	// Signed in 
				// 	const user = userCredential.user;
				// 	console.log(user)
				// })
				.catch((error) => {
					// const errorCode = error.code;
					// const errorMessage = error.message;
					if (error) {
						switch (error.code) {
							case 'auth/invalid-email':
								this.loginError = 'La dirección de email no está bien escrita.'
								//console.log(error)
								break
							case 'auth/wrong-password':
								this.loginError = 'La contraseña no es válida.'
								//console.log(error)
								break
							case 'auth/user-not-found':
								this.loginError = 'No hay ningún usuario con esa dirección de email.'
								//console.log(error)
								break
							default:
								//console.log("Error logging user in:", error);
								set(ref(db, 'errores/Login'), {
									text: 'Error logging user in',
									error: error.message,
									en: db.ServerValue.TIMESTAMP,
								})
						}
					} else {
						this.email = ''
						this.password = ''
						this.loginError = null
					}
				});
		},
	},
}
</script>

<style scoped>
.login {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex: 1 1 auto;
	width: 100%;
	margin: auto;
	overflow-y: auto;
}

.form {
	width: 100%;
	max-width: 40em;
	margin: auto;
	padding: 2em;
	background-color: steelblue;
	max-width: 30em;
	padding-top: 5em;
}

.input {
	margin-bottom: 1.5em;
}
</style>