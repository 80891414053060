<template>
	<header v-cloak v-if="yo">
		<div class="nav">
			<i class="university icon" @click="route('BooksView')"><span></span></i>
			<DropDown v-if="multipleSubjects" :header="'Seleccione una Materia'" :items="dropdownItems" @itemSelected="setSelectedSubject" />
			<i class="ui bar chart icon" v-show="showReport" @click="route('ReportView')"><span></span></i>
			<i class="list layout icon" v-show="showOrders" @click="route('OrdersView')"><span></span></i>
			<i class="list layout icon admin" v-show="isAxonUser" @click="route('AdminListView')"><span></span></i>
			<a v-if="$root.meta.environment == 'staging'" class="ui green basic label">Staging</a>
			<div class="me-name" v-text="yo ? (yo.nombre) : ''"></div>
			<i class="setting icon" @click="route('ProfileView')"></i>
			<i class="info icon" @click="route('InfoView')"></i>
			<i class="sign out icon" @click="logout"></i>
		</div>
	</header>
</template>

<script>
import { getAuth, signOut } from "firebase/auth"
import { db } from '../firebaseInit.js'
import { set, ref } from "firebase/database"
import DropDown from './DropDown.vue'

export default {
	name: 'NavigationBar',
	components: {
		DropDown
	},
	computed: {
		yo: function () {
			return this.$root.yo
		},
		// subjects: function () {
		// 	return this.$root.subjects
		// },
		showReport: function () {
			return this.yo && (this.yo.rol == 'axon_admin' || this.yo.rol == 'axon_operador' || this.yo.rol == 'admin' || this.yo.rol == 'jefe')
		},
		showOrders: function () {
			return this.yo && this.yo.rol == 'delegado'
		},
		multipleSubjects: function () {
			return this.dropdownItems.length > 1
		},
		isAxonUser: function () {
			return this.yo && (this.yo.rol == 'axon_admin' || this.yo.rol == 'axon_operador')
		},
		isAdmin: function () {
			return this.yo && (this.yo.rol == 'axon_admin' || this.yo.rol == 'admin')
		},
		// isPublicZone: function () {
		// 	var zone = window.location.pathname
		// 	if (zone == '/nervioso' || zone == '/respiratorio' || zone == '/medicina') return true
		// 	else return false
		// },
		dropdownItems: function () {
			// Datos para el dropdown de las Materias
			const items = []
			const subjects = this.$root.meta.materias
			const keys = Object.keys(subjects)
			for (const key of keys) {
				items.push({
					value: key,
					text: subjects[key].titulo
				})
			}
			return items
		},
	},
	methods: {
		logout: function () {
			if (this.yo) {
				const auth = getAuth();
				this.$root.route('LoginView')
				signOut(auth).then(() => {
					// Sign-out successful.
				}).catch((error) => {
					console.log(error)
				});
			}
		},
		route: function (view) {
			this.$root.route(view)
		},
		setSelectedSubject: async function (subject) {
			await set(ref(db, 'usuarios/' + this.yo.uid + '/preferencias/materia_actual/'), subject)
			this.$root.currentView = ''
			this.$nextTick(function () {
				this.$root.currentView = 'BooksView'
			})
		},
	}
}
</script>

<style scoped>
.nav {
	display: flex;
	height: 50px;
	padding: 0 5%;
	color: #ccc;
	background-color: black;
	white-space: nowrap;
	align-items: center;
}

header>.nav>i {
	cursor: pointer;
	font-size: 1.7em;
	padding: 0.3em 0.4em;
	margin: 0;
	width: inherit;
	height: inherit;
}

header>.nav>i:hover {
	color: #fff;
}

header>.nav>i>span {
	font: normal normal 400 0.6em 'Fira Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;

}

header .university.icon {
	padding-right: 0.1em
}

header>.nav>.university.icon span:after {
	content: " Biblioteca";
}

header .ui.bar.chart span:after {
	content: " Informe";
}

[v-cloak] {
	display: none;
}

.me-name {
	color: Chocolate;
	margin: 0 0.6em -11px auto;
}


header .list.layout.icon span:after {
	content: " Pedidos";
}

@media screen and (max-width: 1536px) {
	.nav {
		padding: 0 4%;
	}
}

@media screen and (max-width: 1280px) {
	.nav {
		padding: 0 3%;
	}
}

@media screen and (max-width: 1024px) {
	.nav {
		padding: 0 2%;
	}
}

@media screen and (max-width: 640px) {
	.nav {
		padding: 0 1%;
	}
}

@media screen and (max-width: 640px) {

	header>.nav>.university.icon span:after {
		content: "";
	}

	header .ui.bar.chart span:after {
		content: "";
	}

	header .list.layout.icon span:after {
		content: "";
	}
}
</style>