import Vue from 'vue'
import App from './App.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { db } from './firebaseInit.js'
import { ref, get, onValue, onChildChanged } from 'firebase/database'

Vue.config.productionTip = false
Vue.config.debug = true
Vue.config.devtools = true

Vue.filter('formatEU', function (value) {
	var formatterEUR = new Intl.NumberFormat('es-ES', { useGrouping: 'always', minimumFractionDigits: 2 })
	return formatterEUR.format(value)
})

Vue.filter('round', function (value) {
	return Math.round(value)
})

Vue.filter('accountingES', {
	read: function () {
		// return accounting.formatNumber(value, 2, '.', ',')
	},
	write: function () {
		// var unformated = accounting.unformat(value, ',')
		// var formated = accounting.toFixed(unformated, 2) // por si tiene mas de 2 decimales.
		// return accounting.unformat(formated)
	},
})

Vue.filter('fechaCorta', function (timestamp) {
	const theTime = new Date(0)
	theTime.setTime(timestamp)
	return new Intl.DateTimeFormat('es').format(theTime)
})

Vue.filter('timestampPedidos', function (timestamp) {
	const date = new Date(0)
	date.setTime(timestamp)
	const formatter = new Intl.DateTimeFormat('es', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	})
	return formatter.format(date)
})

Vue.filter('longTimestamp', function (timestamp) {
	const date = new Date(0)
	date.setTime(timestamp)
	const formatter = new Intl.DateTimeFormat('es', {
		weekday: 'short',
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true,
	})
	return formatter.format(date)
})

new Vue({
	render: h => h(App),
	data: {
		currentView: null,
		yo: null,
		reportSelectedUser: null,
		meta: null,
	},
	computed: {
		publicSubject: function () {
			const currentPath = window.location.pathname.replace('/', '')
			return this.meta.public_paths[currentPath] || null
		},
	},
	created: async function () {
		const snap = await get(ref(db, `meta`))
		this.meta = snap.val()
		this.setDatabaseEventListeners()
		this.route('LoginView')
	},
	methods: {
		setDatabaseEventListeners: function () {
			// Cada vez que cambia el número de versión (nuevo deploy)
			onChildChanged(ref(db, 'meta/version'), () => {
				window.location.reload(true)
			})

			// Relee meta si cambia algo, por ejemplo el contador de pedidos
			onValue(ref(db, 'meta'), snap => {
				this.meta = snap.val()
			})

			// Cada vez que cambia el estado de la Authenticación
			const auth = getAuth()
			onAuthStateChanged(auth, user => {
				if (user) {
					if (!this.yo) this.readUserData(user.uid)
				} else {
					// console.log('User is signed out')
					this.yo = null
					this.reportSelectedUser = null
				}
			})
		},
		route: function (view) {
			this.currentView = null
			if (!this.yo && this.publicSubject) return (this.currentView = 'BooksView')
			if (!this.yo) return (this.currentView = 'LoginView') // no está autenticado
			if (!this.yo.perfil_completo) return (this.currentView = 'ProfileView') // no permite ver nada hasta que el perfil esté completo
			this.currentView = view
		},
		readUserData: function (uid) {
			// Se usa onValue para que se reflejen los cambios del usuario cuando este se da de alta o cambia algun dato en su perfil.
			onValue(ref(db, 'usuarios/' + uid), snap => {
				this.yo = snap.val()
				this.yo.uid = snap.key
				this.route('BooksView')
			})
		},
	},
}).$mount('#app')
