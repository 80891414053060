<template>
	<div class="region">
		<div class="name"><span v-text="jefe.nombre + ' ' + jefe.apellidos"></span></div>
		<ReportRegionCounters :jefeUid="jefe.uid" />
		<ReportRegionTable :jefeUid="jefe.uid" />
	</div>
</template>

<script>
import ReportRegionCounters from './ReportRegionCounters.vue'
import ReportRegionTable from '../components/ReportRegionTable.vue'

export default {
	name: 'ReportRegion',
	components: {
		ReportRegionCounters,
		ReportRegionTable
	},
	props: ['jefe'],
}
</script>

<style scoped>
.region {
	position: relative;
	background-color: hsla(0, 0%, 5%, 1);
	width: 100%;
	max-width: 60rem;
	margin: 0 auto 2em;
	padding: 1em;
	border: 2px #5c5c5c solid;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.region .name {
	color: silver;
	font-size: 1.2em;
	font-style: italic;
	margin-bottom: 0.5em;
}

.region .name span {
	color: chocolate;
	font-size: 1.3em;
	font-weight: 300;
}
</style>