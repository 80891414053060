<template>
	<!-- modal del form para pedir -->
	<ModalWidget :show.sync="showPedir" class="order-modal">
		<div slot="header"></div>
		<div slot="body" class="order-form">
			<div class="imgWrapper">
				<img class="img-portada" :src="'//' + book.url_imagen">
				<img v-if="book.ebook" class="sello-ebook" src="img/sello_ebook.png" />
			</div>
			<div class="book-data">
				<table class="stats ui three column celled small table">
					<tbody>
						<tr>
							<td>{{ disponible | formatEU }} €</td>
							<td>{{ book.precio | formatEU }} €</td>
							<td>{{ nuevoDisponible | formatEU }} €</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<th>Disponible</th>
							<th>Este libro</th>
							<th>Nuevo saldo</th>
						</tr>
					</tfoot>
				</table>
				<div class="ui tiny form">
					<div style="margin-bottom: .34em;" class="field" :class="{ 'error': !valid.enviar }">
						<select class="ui dropdown" v-model="referencias.enviar">
							<option value="">Seleccione a quién se envía</option>
							<option value="medico">Enviárselo al médico</option>
							<option value="delegado">Enviármelo a mí</option>
						</select>
					</div>
				</div>
				<div v-show="referencias.enviar" class="ui tiny form">
					<legend style="margin-top:0.5em">Datos del Médico Obligatorios</legend>
					<div class="field" :class="{ 'error': !valid.nombre }">
						<input v-model="referencias.nombre" placeholder="Nombre" type="text">
					</div>
					<div class="field" :class="{ 'error': !valid.apellidos }">
						<input v-model="referencias.apellidos" placeholder="Apellidos" type="text">
					</div>
					<div class="field" :class="{ 'error': !valid.centro }">
						<input v-model="referencias.centro" placeholder="Centro de trabajo" type="text">
					</div>
					<div style="margin-bottom: .34em;" class="field" :class="{ 'error': !valid.especialidad }">
						<select class="ui dropdown" v-model="referencias.especialidad">
							<option value="">Especialidad médica</option>
							<option v-for="especialidad in especialidades" :key="especialidad" v-bind:value="especialidad"> {{ especialidad }} </option>
							<option value="Otros">Otros</option>
						</select>
					</div>
					<legend v-show="referencias.enviar == 'delegado'" style="margin-top:0.8em">Datos del Médico Opcionales</legend>
					<div class="field" :class="{ 'error': !valid.telefono }">
						<input v-model="referencias.telefono" placeholder="Teléfono Móvil" type="text">
					</div>
					<div class="field" :class="{ 'error': !valid.direccion }">
						<textarea rows="3" v-model="referencias.direccion" placeholder="Dirección de envío"></textarea>
					</div>
					<div class="field" :class="{ 'error': !valid.poblacion }">
						<input v-model="referencias.poblacion" placeholder="Población" type="text">
					</div>
					<div class="fields" style="margin-bottom:0">
						<div class="field" :class="{ 'error': !valid.cpostal }">
							<input v-model="referencias.cpostal" placeholder="C. Post" type="number">
						</div>
						<div class="field" :class="{ 'error': !valid.provincia }">
							<select class="ui dropdown" v-model="referencias.provincia">
								<option value="">Provincia</option>
								<option v-for="provincia in provincias" :key="provincia" v-bind:value="provincia"> {{ provincia }} </option>
							</select>
						</div>
					</div>
					<legend v-show="referencias.enviar == 'medico'" style="margin-top:0.8em">Datos del Médico Opcionales</legend>
					<!-- <div v-show="referencias.enviar == 'medico'" class="field" :class="{ 'error': !valid.centro }">
						<input v-model="referencias.centro" placeholder="Centro de trabajo" type="text">
					</div> -->
					<div class="field" :class="{ 'error': !valid.email }">
						<input v-model="referencias.email" placeholder="Email" type="text">
					</div>
					<div class="field">
						<input v-model="referencias.onekey" placeholder="ONEKEY (opcional)" type="text">
					</div>
				</div>
				<div style="margin-top:0.5em">
					<div class="ui mini buttons right floated">
						<button class="ui black button" @click.stop.prevent="destroyMe"> Cancelar </button>
						<div class="or" data-text="o"></div>
						<button class="ui grey disabled button" v-show="!isValid"> Pedir </button>
						<button class="ui positive button" @click.stop.prevent="cursarPedido" v-show="isValid && !pedirClicked">
							<i class="checkmark icon"></i>Pedir </button>
						<button class="ui positive loading button" v-show="isValid && pedirClicked"> Pedir </button>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer">
		</div>
	</ModalWidget>
</template>

<script>
/* global  $ */
// Necesaria para jQuery

import { db } from '../firebaseInit.js'
import { ref, push, set, update, runTransaction, serverTimestamp, increment } from "firebase/database"
import ModalWidget from './ModalWidget.vue'
import { Decimal } from 'decimal.js'

var provinciasES = [
	'Álava',
	'Albacete',
	'Alicante',
	'Almería',
	'Ávila',
	'Badajoz',
	'Baleares',
	'Barcelona',
	'Burgos',
	'Cáceres',
	'Cádiz',
	'Castellón',
	'Ciudad Real',
	'Córdoba',
	'La Coruña',
	'Cuenca',
	'Gerona',
	'Granada',
	'Guadalajara',
	'Guipuzcoa',
	'Huelva',
	'Huesca',
	'Jaén',
	'León',
	'Lérida',
	'La Rioja',
	'Lugo',
	'Madrid',
	'Málaga',
	'Murcia',
	'Navarra',
	'Orense',
	'Asturias',
	'Palencia',
	'Las Palmas',
	'Pontevedra',
	'Salamanca',
	'Santa Cruz de Tenerife',
	'Cantabria',
	'Segovia',
	'Sevilla',
	'Soria',
	'Tarragona',
	'Teruel',
	'Toledo',
	'Valencia',
	'Valladolid',
	'Vizcaya',
	'Zamora',
	'Zaragoza',
	'Ceuta',
	'Melilla',
]

export default {
	name: 'BookCardForm',
	components: {
		ModalWidget,
	},
	props: ['book'],
	data: function () {
		return {
			showPedir: false,
			pedirClicked: false,
			showGracias: false,
			referencias: {
				enviar: null,
				nombre: '',
				apellidos: '',
				centro: '',
				especialidad: '',
				telefono: '',
				email: '',
				direccion: '',
				poblacion: '',
				cpostal: null,
				provincia: '',
				onekey: '',
			},
			provincias: provinciasES,
		}
	},
	created: function () {
		this.showPedir = true
	},
	mounted: function () {
		$('.ui.dropdown').dropdown()
	},
	computed: {
		currentSubject: function () {
			if (this.$root.yo) return this.$root.yo.preferencias.materia_actual
			return this.$root.publicSubject || null
		},
		especialidades: function () {
			const especialidades = this.$root.meta.materias[this.currentSubject].especialidades
			return Object.keys(especialidades)
		},
		disponible: function () {
			return new Decimal(this.$root.yo.contadores.asignado).minus(this.$root.yo.contadores.consumido).toNumber()
		},
		nuevoDisponible: function () {
			return new Decimal(this.disponible).minus(this.book.precio).toNumber()
		},
		nuevoConsumido: function () {
			return new Decimal(this.$root.yo.contadores.consumido).plus(this.book.precio).toNumber()
		},
		valid: function () {
			var referencias = this.referencias
			//var patt = new RegExp("^/.+@.+\..+/i$")
			return {
				enviar: referencias.enviar && true,
				nombre: referencias.nombre && referencias.nombre.trim().length >= 3,
				apellidos: referencias.apellidos && referencias.apellidos.trim().length >= 3,
				centro: referencias.centro && referencias.centro.trim().length >= 3,
				especialidad: referencias.especialidad && true,
				telefono: (referencias.telefono && referencias.telefono.trim().length >= 9) || referencias.enviar == 'delegado',
				email: (referencias.email && /^.+@.+\..+$/.test(referencias.email)) || !referencias.email,
				direccion: (referencias.direccion && referencias.direccion.trim().length >= 3) || referencias.enviar == 'delegado',
				cpostal: (referencias.cpostal && referencias.cpostal.trim().length == 5) || referencias.enviar == 'delegado',
				poblacion: (referencias.poblacion && referencias.poblacion.trim().length >= 3) || referencias.enviar == 'delegado',
				provincia: (referencias.provincia && true) || referencias.enviar == 'delegado',
			}
		},
		isValid: function () {
			var valid = this.valid
			return Object.keys(valid).every(function (key) {
				return valid[key]
			})
		},
	},
	methods: {
		cursarPedido: async function () {

			/**
			 * HACE COMPROBACIONES
			 */

			if (this.$root.yo.rol != 'delegado') {
				this.pedirClicked = true // cambia botones para evitar dobles clicks.
				alert('Sólo pueden cursar pedidos los delegados.')
				this.pedirClicked = false
				return
			}
			if (this.nuevoDisponible < 0) {
				this.pedirClicked = true // cambia botones para evitar dobles clicks.
				alert('El saldo disponible no es suficiente para esta compra.')
				this.pedirClicked = false
				return
			}

			this.pedirClicked = true // cambia el botón para evitar dobles clicks.

			/**
			 * OBTIENE EL NÚMERO DE PEDIDO
			 */

			// Incrementa el contador y devuelve el nuevo valor
			// No se puede hacer con increment(1) porque puede que el nodo pedidos no exista
			const transactionResult = await runTransaction(ref(db, 'meta/pedidos'), (currentValue) => {
				return (currentValue || 0) + 1
			})
			// Si falla la transacción logea el error y se sale
			if (!transactionResult.committed) {
				this.logFormError('No ha podido incrementar y obtener el contador de pedidos!')
				this.destroyMe()
				return
			}

			// console.log(transactionResult.snapshot.key, transactionResult.snapshot.val())

			/**
			 * GRABA EL PEDIDO
			 */
			const pedido = {
				pedido_en: serverTimestamp(),
				materia: this.$root.yo.preferencias.materia_actual,
				pedido_por: this.$root.yo,
				libro: this.book,
				referencias: this.referencias,
			}
			const orderNumber = transactionResult.snapshot.toJSON()

			const updates = {
				['pedidos/' + orderNumber]: pedido,
				[`usuarios/${this.$root.yo.uid}/contadores/pedidos`]: increment(1),
				[`usuarios/${this.$root.yo.uid}/contadores/consumido`]: this.nuevoConsumido // No puedo usar increment() con decimales en Firebase porque produce errores de redondeo, así que hay que hacer la suma en local
			};
			update(ref(db), updates).catch((error) => {
				this.logFormError('Error al grabar el pedido ' + orderNumber, error)
				this.destroyMe()
				return
			})

			// Pedido completo. Emite un evento para cerrar el formulario y navegar a la vista de pedidos.
			this.$emit('order_placed')
		},
		logFormError(message, error) {
			const data = {
				text: message,
				error: error || null,
				en: serverTimestamp()
			}
			const newErrorRef = push(ref(db, 'errores/OrderForm'))
			set(newErrorRef, data)
		},
		destroyMe: function () {
			//TODO: emitir un evento.
			this.$parent.orderFormExist = false
		},
	},
}
</script>

<style scoped>
.order-form {
	display: flex;
	justify-content: space-evenly;
	padding: 40px 0;
}

.imgWrapper {
	position: relative;
}

.img-portada {
	width: 240px;
	height: 340px;
	border: solid 1px #fff;
}

.sello-ebook {
	position: absolute;
	width: 64px;
	height: 64px;
	top: 268px;
	left: 88px;
	border: inherit;
	margin: inherit;
}


.book-data {
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
	max-width: 340px;
}

.stats.table {
	text-align: center;
	border: none;
}

.stats.table tbody {
	font-weight: 600;
}

.stats.table td {
	padding: 0.32em 0.5em !important;
}

.stats.table tfoot th {
	padding: 0.4em 0.5em !important;
	font-size: 0.92em;
	background-color: grey;
	color: #fff;
}

.order-form .ui.small.form {
	margin-bottom: 1em;
}

.order-form .ui.small.form legend {
	padding-left: 1em;
	font-weight: 500;
	color: #444;
}

.order-form .ui.small.form input {
	background: #fff;
}

.order-form .ui.form .field {
	margin-bottom: 0.4em;
}

.order-form>div>div>button>i {
	font-size: 1.5em;
}

.order-form>div>div {
	width: 100%;
}

.ebook-ficha {
	position: absolute;
	width: 64px;
	height: 64px;
	top: 268px;
	left: 88px;
	border: inherit;
	margin: inherit;
}

.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='time'],
.ui.form input[type='text'],
.ui.form input[type='url'],
.ui.form textarea {
	font-family: inherit;
	padding: 0.5em 0.8em;
	font-size: 1.1em;
	background: hsl(0, 0%, 100%);
	color: #222;
}

.ui.form .field.error input[type=text],
.ui.form .field.error input[type='number'],
.ui.form .field.error textarea {
	background: hsl(15, 100%, 96%);
	border-color: hsl(15, 42%, 77%);
	color: hsl(15, 48%, 42%);
}

.ui.form ::-webkit-input-placeholder {
	color: hsl(0, 0%, 71%);
}

.ui.form .error ::-webkit-input-placeholder {
	color: hsl(0, 20%, 62%);
}

.ui.button {
	font-family: inherit;
	font-weight: 400;
}

/*.ui.form > .ui.button {
    margin-top: 1.4em;
}*/
.ui.basic.green.label {
	color: hsla(134, 80%, 36%, 1) !important;
}

.ui.form .field>label {
	font-size: 0.94em;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.85);
}

.ui.form .field.error>label {
	color: rgba(0, 0, 0, 0.8);
}
</style>