<template>
	<div class="BooksView view">
		<div class="title" v-if="multipleSubjects" v-text="title"></div>
		<div class="section first" v-show="pBooks.length > 0">Libros</div>
		<div class="listado">
			<BookCard v-for="book in pBooks" :key="book.numero" :book="book" />
		</div>
		<div class="section" v-show="eBooks.length > 0">e-Books</div>
		<div class="listado">
			<BookCard v-for="book in eBooks" :key="book.numero" :book="book" />
		</div>
	</div>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, onChildAdded, query, orderByChild } from "firebase/database"
import BookCard from '../components/BookCard.vue'

export default {
	name: 'BooksView',
	components: {
		BookCard
	},
	data: function () {
		return {
			books: [],
			selectedBook: {},
		}
	},
	computed: {
		title: function () {
			return this.$root.meta.materias[this.currentSubject].titulo
		},
		multipleSubjects: function () {
			return Object.keys(this.$root.meta.materias).length > 1
		},
		pBooks: function () {
			return this.books.filter(book => !book.ebook)
		},
		eBooks: function () {
			return this.books.filter(book => book.ebook)
		},
		currentSubject: function () {
			if (this.$root.yo) return this.$root.yo.preferencias.materia_actual
			return this.$root.publicSubject || null
		},
	},
	created: function () {
		this.getBooks()
	},
	methods: {
		getBooks: function () {
			this.books = []
			const booksRef = query(ref(db, `libros/${this.currentSubject}`), orderByChild('numero'))
			let that = this
			onChildAdded(booksRef, function (snap) {
				let book = snap.val()
				if (!book.agotado && !book.oculto) {
					book.isbn = snap.key
					that.books.push(book)
				}
			})
		},
	}
}
</script>

<style scoped>
.BooksView {
	padding-bottom: 2rem;
	overflow-y: scroll;
}

.BooksView::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

.BooksView::-webkit-scrollbar-thumb {
	background: #434343;
	border-radius: 16px;
	box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
}

.BooksView::-webkit-scrollbar-track {
	background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}

.title {
	font-size: 3.6em;
	line-height: 1em;
	color: hsla(0, 0%, 45%, 1);
	font-family: Georgia, serif;
	width: 100%;
	text-align: center;
	padding-top: 0.5rem;
}

.section {
	font-size: 3em;
	line-height: 1em;
	color: hsl(25, 75%, 46%);
	font-family: Georgia, serif;
	width: 100%;
	text-align: center;
	padding-top: 4px;
	margin: 2rem 0;
	background: linear-gradient(90deg, hsla(0, 0%, 25%, 0.05) 5%, hsla(0, 0%, 32%, 1) 30%, hsla(0, 0%, 32%, 1) 70%, hsla(0, 0%, 25%, 0.05) 95%);
}

.section.first {
	/* background: inherit; */
	margin: 1.6rem 0 0.9rem;
}

.listado {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0.5em;
	gap: 15px;
}
</style>