<template>
	<div class="counters">
		<div class="ui labeled button small" tabindex="0">
			<div class="ui grey button small"> Presupuesto </div>
			<a class="ui basic left pointing grey label small"> {{ asignado | formatEU }} € </a>
		</div>
		<div class="ui labeled button small" tabindex="0">
			<div class="ui purple button small"> {{ pedidos }} Pedidos </div>
			<a class="ui basic left pointing purple label small"> {{ consumido | formatEU }} € </a>
		</div>
		<div class="ui labeled button small" tabindex="0">
			<div class="ui violet button small"> Disponible </div>
			<a class="ui basic left pointing violet label small"> {{ asignado - consumido | formatEU }} € </a>
		</div>
	</div>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, query, equalTo, orderByChild, onChildAdded } from "firebase/database"

export default {
	name: 'ReportRegionCounters',
	props: ['jefeUid'],
	data: function () {
		return {
			asignado: 0,
			pedidos: 0,
			consumido: 0,
		}
	},
	created: function () {
		this.getStats()
	},
	methods: {
		getStats: function () {
			const that = this
			let dataRef
			if (!this.jefeUid) {
				// Si no se recibe el uid de un jefe obtiene los contadores de todos los delegados
				dataRef = query(ref(db, 'usuarios'), orderByChild('rol'), equalTo('delegado'))
			} else {
				// Obtiene sólo los contadores de los delegados cuyo superior es el recibido en las propiedades
				dataRef = query(ref(db, 'usuarios'), orderByChild('superior'), equalTo(this.jefeUid))
			}
			onChildAdded(dataRef, function (snap) {
				const counters = snap.val().contadores
				that.asignado += counters.asignado
				that.pedidos += counters.pedidos
				that.consumido += counters.consumido
			})
		},
	}
}
</script>

<style scoped>
.ui.button {
	margin-right: 0.5rem;
}

.ui.button,
a.ui.label {
	cursor: default;
}

.ui.grey.button {
	background-color: hsl(0, 0%, 30%);
}

.ui.green.button {
	background-color: hsl(120, 56%, 28%);
}

.ui.purple.button {
	background-color: hsl(300, 82%, 27%);
}

.ui.violet.button {
	background-color: hsl(230, 42%, 32%);
}

.ui.basic.grey.label {
	color: hsl(0, 0%, 30%) !important;
	border-color: hsl(0, 0%, 30%) !important;
}

.ui.basic.purple.label {
	color: hsl(300, 100%, 25%) !important;
	border-color: hsl(300, 100%, 25%) !important;
}

.ui.basic.violet.label {
	color: hsl(242, 34%, 48%) !important;
	border-color: hsl(242, 34%, 48%) !important;
}
</style>