<template>
	<div class="modal-mask" v-show="show" transition="modal">
		<div class="modal-wrapper">
			<div class="modal-container">
				<!-- <div class="modal-header">
					<slot name="header"> default header </slot>
				</div> -->
				<div class="modal-body">
					<slot name="body"> default body </slot>
				</div>
				<!-- <div class="modal-footer">
					<slot name="footer"> default footer <button class="modal-default-button" @click="show = false"> OK </button>
					</slot>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModalWidget',
	props: {
		show: {
			type: Boolean,
			required: true,
			twoWay: true,
		},
	},
	data: function () {
		return {}
	},
	methods: {
		// closeModal: function () { this.show = false}
	}
}
</script>

<style scoped>
.modal-container {
	background-color: hsl(104, 20%, 90%);
	padding: 0;
}
</style>