// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDUXOkBjDMr5GprLiMCD7fsjfncb39aW3s',
	authDomain: 'bib-pfizer-23.firebaseapp.com',
	databaseURL: 'https://bib-pfizer-23-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'bib-pfizer-23',
	storageBucket: 'bib-pfizer-23.appspot.com',
	messagingSenderId: '616723781076',
	appId: '1:616723781076:web:bc39d5424e577ce9d0f241',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

//initialize firebase auth
const auth = getAuth()

// Initialize Realtime Database and get a reference to the service
const db = getDatabase(app)

export { app, auth, db }
