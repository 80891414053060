<template>
	<div class="ddown">
		<div class="trigger" @click="isVisible = !isVisible">
			<span class="arrow"></span>
		</div>
		<div class="menu" v-if="isVisible">
			<div class="header" v-if="header">{{ header }}</div>
			<div class="item" v-for="item in items" :key="item.value" @click="selectItem(item.value)">{{ item.text }}</div>
		</div>
	</div>
</template>

<script>
/**
 * Autor: Pedro Marbán
 */
export default {
	name: 'DropDown',
	props: {
		header: String,
		items: Array,
	},
	data: function () {
		return {
			isVisible: false,
		}
	},
	created: function () {
		this.listenClicks()
	},
	methods: {
		selectItem: function (value) {
			this.$emit('itemSelected', value)
			this.isVisible = false
		},
		listenClicks: function () {
			const that = this
			document.addEventListener('click', function handleClickOutsideBox(event) {
				const menu = document.querySelector('.ddown .menu')
				const trigger = document.querySelector('.ddown .trigger')
				const arrow = document.querySelector('.ddown .arrow')
				if (menu && !menu.contains(event.target) && event.target !== trigger && event.target !== arrow) {
					that.isVisible = false
				}
			})

		},
	},
}
</script>


<style scoped>
.ddown {
	position: relative;
	height: 100%;
}

.trigger {
	cursor: pointer;
	width: 20px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.arrow {
	margin-bottom: 13px;
	width: 0;
	height: 0;
	border-top: 9px solid chocolate;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
}

.trigger:hover .arrow {
	border-top-color: white;
}

.menu {
	position: absolute;
	z-index: 100;
	padding: 0.8em 0;
	/* background-color: indigo; */
	background-color: hsl(0, 0%, 16%);
	border-radius: 0.4rem;
	transition: opacity 0.1s ease;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
	font-size: 0.9rem;
	font-weight: 400;
}

.header {
	cursor: default;
	font-size: 0.8em;
	font-weight: bold;
	text-transform: uppercase;
	padding: 0.8em 1em;
}

.item {
	cursor: pointer;
	padding: 0.8em 1em;
	color: white;
}

.item:hover {
	background-color: hsl(25, 80%, 25%);
}
</style>