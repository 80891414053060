<template>
	<div class="informe">
		<!-- <div class="title" v-text="title"></div> -->
		<div class="region" v-if="isAdmin">
			<a v-show="!writingReportFile" class="svg_excel" @click.stop.prevent="downloadOrders" data-tooltip="descargar pedidos" data-position="top right">
				<img src="img/Microsoft_Excel_2013_logo.svg" />
			</a>
			<a v-if="writingReportFile" class="svg_excel" click.stop.prevent="">
				<i class="big green loading spinner icon"></i>
			</a>
			<div class="name"><span>Resumen por Regiones</span></div>
			<ReportRegionCounters />
			<ReportRegionTable />
		</div>
		<ReportRegion v-for="jefe in jefes" :key="jefe.uid" :jefe="jefe" />
	</div>
</template>

<script>
import { db } from '../firebaseInit.js'
import { ref, query, equalTo, orderByChild, onChildAdded, get } from "firebase/database"
import { saveAs } from 'file-saver'
import ReportRegionCounters from '../components/ReportRegionCounters.vue'
import ReportRegionTable from '../components/ReportRegionTable.vue'
import ReportRegion from '../components/ReportRegion.vue'

export default {
	name: 'ReportView',
	components: {
		ReportRegionCounters,
		ReportRegionTable,
		ReportRegion
	},
	data: function () {
		return {
			jefes: [],
			writingReportFile: false,
		}
	},
	computed: {
		isAdmin: function () {
			return 'admin' == this.$root.yo.rol || 'axon_admin' == this.$root.yo.rol || 'axon_operador' == this.$root.yo.rol
		},
	},
	created: function () {
		this.getJefes()
	},
	methods: {
		getJefes: function () {
			// Si es un Jefe, sólo se pone el mismo
			if ('jefe' == this.$root.yo.rol) this.jefes.push(this.$root.yo)
			// Si es un admin, pone todos los jefes de esta biblioteca
			if (this.isAdmin) {
				var that = this
				// console.log('getJefes >', idBiblioteca)
				const dataRef = query(ref(db, 'usuarios'), orderByChild('rol'), equalTo('jefe'))
				onChildAdded(dataRef, function (snap) {
					// if (snap.val().bibliotecas.hasOwnProperty(idBiblioteca)) {
					// if (Object.prototype.hasOwnProperty.call(snap.val().bibliotecas, idBiblioteca)) {
					var jefe = snap.val()
					jefe.uid = snap.key
					that.jefes.push(jefe)
					// }
				})

			}
		},
		downloadOrders: async function () {
			this.writingReportFile = true
			var firstRow = 1
			var r = firstRow // row to write
			// style options
			var st = {
				default: {
					font: {
						sz: 14,
						color: 'FF222222',
					},
				},
				nro: {
					numFmt: '#,##0',
					font: {
						sz: 14,
						color: {
							rgb: 'FF222222',
						},
					},
				},
				euro: {
					numFmt: '#,##0.00 €;[Red]-#,##0.00 €',
					font: {
						sz: 14,
						color: {
							rgb: 'FF222222',
						},
					},
				},
				fecha: {
					numFmt: 'dd/mm/yyy hh:mm:ss',
					font: {
						sz: 14,
						color: {
							rgb: 'FF222222',
						},
					},
				},
				headerDark: {
					fill: {
						fgColor: {
							rgb: 'FF444444',
						},
					},
					font: {
						color: {
							rgb: 'FFFFFFFF',
						},
						sz: 14,
						bold: true,
					},
				},
				headerMergedDark: {
					alignment: {
						horizontal: 'center',
					},
					fill: {
						fgColor: {
							rgb: 'FF444444',
						},
					},
					font: {
						color: {
							rgb: 'FFFFFFFF',
						},
						sz: 14,
						bold: true,
					},
				},
			}
			var worksheet = {} // hoja
			var exportBook = new Workbook() // libro
			setWidths(worksheet)
			mergeCells(worksheet)
			writeHeader(worksheet)

			var that = this
			const snap = await get(ref(db, 'pedidos'))
			snap.forEach((childSnap) => {
				writeRow(worksheet, childSnap)
			})
			writeFooter(worksheet)
			// añade hoja al libro
			exportBook.SheetNames.push('Pedidos')
			exportBook.Sheets.Pedidos = worksheet
			// crea el binario y lo descarga
			var wbout = window.XLSX.write(exportBook, { bookType: 'xlsx', bookSST: false, type: 'binary' })
			const subDomain = window.location.hostname.split('.')[0]
			saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'pedidos ' + subDomain + '.xlsx')
			that.writingReportFile = false

			function mergeCells(ws) {
				var R = firstRow - 1
				ws['!merges'] = [
					{ s: { c: 0, r: R }, e: { c: 1, r: R } },
					{ s: { c: 3, r: R }, e: { c: 5, r: R } },
					{ s: { c: 7, r: R }, e: { c: 9, r: R } },
					{ s: { c: 11, r: R }, e: { c: 18, r: R } },
				]
			}

			function setWidths(ws) {
				ws['!cols'] = [
					{ wch: 8 },
					{ wch: 21 },

					{ wch: 2 },
					{ wch: 15 },
					{ wch: 15 },
					{ wch: 30 },

					{ wch: 2 },
					{ wch: 15 },
					{ wch: 30 },
					{ wch: 5 },

					{ wch: 2 },
					{ wch: 15 },
					{ wch: 15 },
					{ wch: 30 },
					{ wch: 15 },
					{ wch: 15 },
					{ wch: 15 },
					{ wch: 15 },
					{ wch: 15 },
				]
			}

			function writeHeader(ws) {
				ws['A' + r] = { v: 'Pedido', t: 's', s: st.headerMergedDark }
				ws['D' + r] = { v: 'Delegado', t: 's', s: st.headerMergedDark }
				ws['H' + r] = { v: 'Libro', t: 's', s: st.headerMergedDark }
				ws['L' + r] = { v: 'Médico', t: 's', s: st.headerMergedDark }

				r++
				ws['A' + r] = { v: 'Número', t: 's', s: st.headerDark }
				ws['B' + r] = { v: 'Fecha', t: 's', s: st.headerDark }

				ws['D' + r] = { v: 'Nombre', t: 's', s: st.headerDark }
				ws['E' + r] = { v: 'Apellidos', t: 's', s: st.headerDark }
				ws['F' + r] = { v: 'Email', t: 's', s: st.headerDark }

				ws['H' + r] = { v: 'Importe', t: 's', s: st.headerDark }
				ws['I' + r] = { v: 'Título', t: 's', s: st.headerDark }
				ws['J' + r] = { v: 'Nro.', t: 's', s: st.headerDark }

				ws['L' + r] = { v: 'Nombre', t: 's', s: st.headerDark }
				ws['M' + r] = { v: 'Apellidos', t: 's', s: st.headerDark }
				ws['N' + r] = { v: 'Email', t: 's', s: st.headerDark }
				ws['O' + r] = { v: 'Centro', t: 's', s: st.headerDark }
				ws['P' + r] = { v: 'Población', t: 's', s: st.headerDark }
				ws['Q' + r] = { v: 'Provincia', t: 's', s: st.headerDark }
				ws['R' + r] = { v: 'Especialidad', t: 's', s: st.headerDark }
				ws['S' + r] = { v: 'OneKey', t: 's', s: st.headerDark }
				r++
			}

			function writeRow(ws, childSnap) {
				if (!childSnap.val().anulado) {
					var order = childSnap.val()
					order.nro = childSnap.key
					ws['A' + r] = { v: order.nro, t: 'n', s: st.nro }
					ws['B' + r] = { v: JSDateToExcelDate(new Date(order.pedido_en)), t: 'n', s: st.fecha }

					ws['D' + r] = { v: order.pedido_por.nombre, t: 's', s: st.default }
					ws['E' + r] = { v: order.pedido_por.apellidos, t: 's', s: st.default }
					ws['F' + r] = { v: order.pedido_por.email, t: 's', s: st.default }

					ws['H' + r] = { v: order.libro.precio, t: 'n', s: st.euro }
					ws['I' + r] = { v: order.libro.titulo, t: 's', s: st.default }
					ws['J' + r] = { v: order.libro.numero, t: 'n', s: st.nro }

					ws['L' + r] = { v: order.referencias.nombre, t: 's', s: st.default }
					ws['M' + r] = { v: order.referencias.apellidos, t: 's', s: st.default }
					ws['N' + r] = { v: order.referencias.email, t: 's', s: st.default }
					ws['O' + r] = { v: order.referencias.centro, t: 's', s: st.default }
					ws['P' + r] = { v: order.referencias.poblacion, t: 's', s: st.default }
					ws['Q' + r] = { v: order.referencias.provincia, t: 's', s: st.default }
					ws['R' + r] = { v: order.referencias.especialidad, t: 's', s: st.default }
					ws['S' + r] = { v: order.referencias.onekey, t: 's', s: st.default }
					r++
				}
			}

			// function writeFooter(ws) {
			function writeFooter() {
				worksheet['H' + r] = { f: 'SUM(H' + (firstRow + 2) + ':H' + (r - 1) + ')', t: 'n', s: st.euro }
				r++
				worksheet['!ref'] = 'A' + firstRow + ':S' + r
			}

			// workBook class
			function Workbook() {
				if (!(this instanceof Workbook)) return new Workbook()
				this.SheetNames = []
				this.Sheets = {}
			}

			// string to ArrayBuffer
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length)
				var view = new Uint8Array(buf)
				for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
				return buf
			}

			function JSDateToExcelDate(inDate) {
				return 25569.0 + (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) / (1000 * 60 * 60 * 24)
			}
		},
	},
}
</script>

<style scoped>
.informe {
	flex: 1 1 auto;
	width: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 2rem;
}

.informe::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

.informe::-webkit-scrollbar-thumb {
	background: #434343;
	border-radius: 16px;
	box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
}

.informe::-webkit-scrollbar-track {
	background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}

/* .title {
	font-size: 3.6em;
	line-height: 1em;
	color: hsla(0, 0%, 45%, 1);
	font-family: Georgia, serif;
	width: 100%;
	text-align: center;
	padding: 1.5rem 0 1rem;
} */

.svg_excel {
	position: absolute;
	top: 8px;
	right: 8px;
	width: 48px;
	cursor: pointer;
}

.svg_excel img {
	width: 48px;
}

.region {
	position: relative;
	background-color: hsla(0, 0%, 5%, 1);
	width: 100%;
	max-width: 60rem;
	margin: 0 auto 2em;
	padding: 1em;
	border: 2px #5c5c5c solid;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.region .name {
	color: silver;
	font-size: 1.2em;
	font-style: italic;
	margin-bottom: 0.5em;
}

.region .name span {
	color: chocolate;
	font-size: 1.3em;
	font-weight: 300;
}
</style>